<template>
  <div>
    <WhoWeAre />
    <MoreContent>
      <template #header>
        {{ $t("heading.about_us") }}
      </template>
      <template #body>
        <p v-html="aboutUs"></p>
      </template>
    </MoreContent>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AboutUs",
  components: {
    WhoWeAre: () => import("@/components/Home/WhoWeAre"),
    MoreContent: () => import("@/components/MoreContent")
  },
  computed: {
    ...mapGetters(["settings"]),
    aboutUs() {
      return this.settings("about_us");
    }
  }
};
</script>
